import * as React from "react";
import { connect } from "react-redux";
import { mapDispatchToProps } from "../../redux/actions";
import { getRepsByOfficeId } from "../../api-calls/projectDataService";
import "../../css/ProjectModal.scss";
import { ProjectStatusFilterBy } from "../../interfaces/interfaces";
import { isEmptyArray } from "../../utilities/helpers";

// expected props
interface IProps {
  updateRedux: any;
  reduxModals: any;
  reduxProjectManagement: any;
}
// local state
interface IState {
  projectName: string;
  consultingEngineer: string;
  contractor: string;
  city: string;
  state: string;
  jobStatus: string;
  repContacts: any[];
  selectedRepContact: any;
  selectedRepContactEmail: string;
  loadingInProgress: boolean;
}

class TransferProjectModal extends React.Component<IProps, IState> {
  public state: IState = {
    projectName: "",
    consultingEngineer: "",
    contractor: "",
    city: "",
    state: "",
    jobStatus: ProjectStatusFilterBy[ProjectStatusFilterBy.Design],
    repContacts: [],
    selectedRepContact: null,
    selectedRepContactEmail: "",
    loadingInProgress: false
  };

  //This is a specific modal for adding or editing a project.  Input is project name.
  async componentDidMount() {
    //Get list of sales reps in the same office
    this.setState({ loadingInProgress: true });
    let response = await getRepsByOfficeId();
    this.setState({ loadingInProgress: false });

    this.CreateContactsArray(response.data.relatedContacts);
  }

  private CreateContactsArray(contacts: any[]) {
    if (isEmptyArray(contacts)) {
      return;
    }
    let arry = contacts.map((item: any, index: number) => {
      return {
        id: index + 1,
        active: item.active,
        firstName: item.firstName,
        lastName: item.lastName,
        email: item.email
      };
    });
    //Add choose selection.
    let choose: any = {
      id: 0,
      active: true,
      firstName: "",
      lastName: "Choose",
      email: ""
    };
    arry.push(choose);
    //Sort array by id ascending so that the option "Choose" is first.
    arry.sort((a, b) => (a.id > b.id ? 1 : b.id > a.id ? -1 : 0));
    this.setState({ repContacts: arry });
  }

  private selectContact(email: any) {
    this.setState({ selectedRepContactEmail: email });
  }

  private getContactOptions() {
    const options = this.state.repContacts;

    if (options) {
      return options.map((item: any, index: number) => (
        <option key={index} value={item.email}>
          {item.firstName !== "" ? item.lastName + ", " + item.firstName : item.lastName}
        </option>
      ));
    }
    return <option></option>;
  }

  //------------------------------------------------------------------------------
  public render() {
    return (
      <div className="newProject">
        <div className="header">Transfer Job</div>

        <div className="InputRow">
          <div className="proj-modal-label">Salesperson</div>
          <div className="projectInput">
            {this.state.loadingInProgress ? <div className="project-loading"></div> : null}
            <select className="projectInput" value={this.state.selectedRepContactEmail} onChange={(event) => this.selectContact(event.currentTarget.value)}>
              {this.getContactOptions()}
            </select>
          </div>
        </div>

        <div className="acc-spacer"></div>

        <div className="proj-transfer">
          Warning: You are about to transfer this job to another salesperson in your office and you will no longer have control or access to it.
        </div>

        <div className="button-container">
          <div className="proj-create-btn" onClick={() => this.userResponse("ok")}>
            Transfer
          </div>
          <div className="proj-cancel-btn" onClick={() => this.userResponse("cancel")}>
            Cancel
          </div>
        </div>
      </div>
    );
  }
  //This will trigger the componentDidUpdate method on ProjectManagement.tsx
  private userResponse(response: string) {
    this.props.updateRedux("UPDATE_MODALS", { showModal: false, userResponse: response });
    console.log(response);
    if (response === "ok" && this.state.selectedRepContactEmail !== "") {
      this.props.updateRedux("UPDATE_PROJECT_MANAGEMENT", {
        selectedRepContactEmail: this.state.selectedRepContactEmail
      });
    }
    if (response === "cancel") {
      this.props.updateRedux("UPDATE_PROJECT_MANAGEMENT", { selectedRepContactEmail: "" });
    }
  }
}

//------------------ Redux ----------------------------
function mapStateToProps(state: any) {
  return {
    reduxProjectManagement: state.reduxProjectManagement,
    reduxModals: state.reduxModals
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(TransferProjectModal);
