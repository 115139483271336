//Used for all redux reducers
export interface IAction {
  type: string;
  newState: object;
}

//Used for reduxValidation
export interface IModifyPropertyError {
  reduxObjectName: string;
  propertyName: string;
  errorMessage: string;
}

//Used for reduxNotifications
export interface INotification {
  id: string;
  notificationType: string;
  title: string;
  content: any;
}

//Used for UserInfo (Identity Service)
export interface IUserInfo {
  userId : string;
  userType :string ;
  firstName : string;
  lastName : string;
  email : string;
  officeMembershipsArr : any[];
}

export interface Ierror {
  validationError: string | null;
  modifyPropertyError: string | null;
}
export enum ACCUArrangement {
  SingleCircuitSideBySide_P1,
  SingleCircuitOverUnderTopTunnel_P2,
  TwoCircuitSideBySideL_P3,
  TwoCircuitOverUnderParallel_P4,
  SingleCircuitOverUnderFullHeight_S1,
  TwoCircuitOverUnderFullHeightParallel_S2,
  TwoCircuitSideBySideInSeries_S3,
  SingleCircuitOverUnderExhaust_M1,
  SingleCircuitOverUnderEnd_M2,
  SingleCircuitSideBySideEndTurned_M3,
  SingleCircuitSideBySideEnd_M4,
  TwoCircuitOverUnderExhaustSeries_M5,
  TwoCircuitOverUnderEndSeries_M6,
  TwoCircuitSideBySideEndParallel_M7,
  Automatic = "Automatic",
  SingleCircuitSingleTunnelEnd_P5 = 15,
  SingleCircuitSingleTunnelEndTurned_M8 = 16,
  TwoCircuitSingleTunnelEndParallel_P6 = 17,
  TwoCircuitSingleTunnelEndSeries_M9 = 18
}
export enum AirTunnelOrientation {
  NotSet = 0,
  Stacked = 1,
  SideBySide = 2,
}
export interface IHeatWheelOption{
  isDefaultSelection: boolean,
  modelNumber: string,
  WheelDiameter: number,
  totalEffectiveness: number,
  summerLAT: number,
  summerWetBulbLAT: number,
  winterLAT: number,
  pressureDrop: number,
  summerSensibleEff: number,
  summerLatentEff: number,
  summerTotalEff: number,
  winterSensibleEff: number,
  winterLatentEff: number,
  winterTotalEff: number
}
export enum SortBy {
  None = 0,
  ASC = 1,
  DESC = 2
}
export enum ProjectStatusFilterBy {
  All = 0,
  Design = 1,
  Booking = 2,
  Release = 3
}